
















import Vue from 'vue';
import AdminTemplate from '@/components/templates/adminTemplate/AdminTemplate.vue';
import { contentServices } from '@/services/contentService';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import { SpinnerState } from '@/enums/spinnerState';
import { MessageType } from '@/enums/messageType';
import { dialogueMessageService } from '@/services/dialogueMessageService';
import { PageContent } from '@/api/interfaces/content/app/pageContent';
import { EntryListContent } from '@/api/interfaces/content/common/entryListContent';
import { adminRepository } from '@/api';
import { alertService } from '@/services/alertService';
import { ServiceError } from '@/api/interfaces/serviceError';
import { Application } from '@/api/interfaces/application/application';
import { Pagination } from '@/api/interfaces/common/pagination';
import { AdminUpdateDataModel } from '@/types/app/admin/adminUpdateDataModel';
import { isServiceError } from '@/api/interfaces/typeGuards';

export default Vue.extend({
    name: 'Admin',
    components: {
        AdminTemplate,
        SpinnerArea,
    },
    data: () => {
        return {
            spinner: SpinnerState.off,
            gridSpinner: SpinnerState.off,
            adminContent: null as PageContent | null,
            enumsContent: null as EntryListContent | null,
            applications: [] as Application[],
            pagination: {} as Pagination,
            adminUpdateData: new AdminUpdateDataModel(),
            contentLoaded: false,
        };
    },
    created() {
        if (this.$route.query.code === 'unauthorized') {
            dialogueMessageService.show('unauthorized', MessageType.error);
        }
    },
    async mounted() {
        this.spinner = SpinnerState.opaque;

        const content = await contentServices.app.requestContentOnce();
        this.adminContent = content.pages.adminContent;
        this.enumsContent = content.enumsContent;

        this.contentLoaded = !!this.adminContent && !!this.enumsContent;

        this.adminUpdateData = new AdminUpdateDataModel();
        await this.updateApplications(this.adminUpdateData);

        this.spinner = SpinnerState.off;
    },
    methods: {
        updateApplicationsAfterDelete(deletedApplicationId: string): void {
            this.applications = this.applications.filter(
                (application) =>
                    application.applicationId !== deletedApplicationId,
            );
        },
        async updateApplications(data: AdminUpdateDataModel): Promise<void> {
            this.gridSpinner = SpinnerState.transparent;

            try {
                const applicationResult = await adminRepository.fetchApplications(
                    data,
                );
                this.applications = applicationResult.applications;
                this.pagination = {
                    limit: applicationResult.limit,
                    offset: applicationResult.offset,
                    results: applicationResult.results,
                    total: applicationResult.total,
                };
            } catch (error) {
                if (isServiceError(error)) {
                    alertService.clear();
                    alertService.pushAlert({
                        alert: {
                            type: MessageType.error,
                            identifier: (error as ServiceError).messageCode,
                        },
                        displayDuration: 0,
                    });
                }
            }

            this.gridSpinner = SpinnerState.off;
        },
    },
});
