

























import Vue, { PropType } from 'vue';
import AdminGrid from '@/components/organisms/adminGrid/AdminGrid.vue';
import PageTemplate from '@/components/templates/pageTemplate/PageTemplate.vue';
import { Pagination } from '@/api/interfaces/common/pagination';
import { SpinnerState } from '@/enums/spinnerState';
import { AdminUpdateDataModel } from '@/types/app/admin/adminUpdateDataModel';

export default Vue.extend({
    name: 'AdminTemplate',
    components: {
        PageTemplate,
        AdminGrid,
    },
    props: {
        applications: {
            type: Array,
            required: true,
        },
        pagination: {
            type: Object as PropType<Pagination>,
            required: true,
        },
        adminContent: {
            type: Object,
            required: true,
        },
        enumsContent: {
            type: Object,
            required: true,
        },
        gridSpinner: {
            type: String as PropType<SpinnerState>,
            required: true,
        },
        adminUpdateData: {
            type: Object as PropType<AdminUpdateDataModel>,
            default: new AdminUpdateDataModel(),
        },
    },
});
