var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-col md:flex-row md:justify-left my-4 gap-4"},[_c('div',[_c('AppButton',{attrs:{"label":_vm.content.migrateApplicantButtonText},on:{"button-click":_vm.prepareMigratingApplicant}})],1),_c('div',[_c('AppButton',{attrs:{"label":_vm.content.exportButtonText},on:{"button-click":_vm.prepareExportingApplications}})],1),_c('div',[_c('FormField',{attrs:{"model":_vm.applyMigrationsModel,"align-field":"singleLine"}},[_c('FormBooleanCheckbox',{attrs:{"model":_vm.applyMigrationsModel,"content":{ label: _vm.content.dataApplyMigrationsText }}})],1)],1)]),_c('AdminPagination',{attrs:{"content":_vm.content.adminPagination,"pagination":_vm.pagination,"admin-update-data":_vm.internalAdminUpdateData,"enums-content":_vm.enumsContent},on:{"query-changed":_vm.handleQueryChanged,"page-changed":_vm.handlePageChanged,"date-sent-at-changed":_vm.handleDateSentAtChanged,"statuses-changed":_vm.handleStatusesChanged,"filter-reset":_vm.handleFilterReset}}),_c('SpinnerArea',{attrs:{"state":_vm.spinnerState}},[_c('AppAlerts'),(!_vm.hasApplications)?_c('AppContent',{attrs:{"content":_vm.content.noApplicationsText}}):_c('AppScrollbar',{staticClass:"w-full"},[_c('table',{staticClass:"overview table-auto w-full"},[_c('thead',[_c('tr',[_vm._l((_vm.gridColumns),function(gridColumn){return _c('th',{key:gridColumn.key,staticClass:"text-primary align-top text-left py-4 pr-5 dea-rich-text",domProps:{"innerHTML":_vm._s(
                                !!_vm.content.columns[gridColumn.key]
                                    ? _vm.content.columns[gridColumn.key].name
                                    : ''
                            )}})}),_c('th',{attrs:{"colspan":"2"}})],2)]),_c('tbody',_vm._l((_vm.entries),function(entry){return _c('tr',{key:entry.applicationId,staticClass:"odd:bg-surface-variant"},[_vm._l((_vm.gridColumns),function(gridColumn){return _c('td',{key:gridColumn.key},[(gridColumn.key == 'status')?_c('span',{staticClass:"dea-rich-text",domProps:{"innerHTML":_vm._s(
                                    _vm.enumsContent.applicationStatus[
                                        _vm.camelCase(entry[gridColumn.key])
                                    ]
                                )}}):_c('span',{staticClass:"dea-rich-text",domProps:{"innerHTML":_vm._s(entry[gridColumn.key])}})])}),_c('td',{staticClass:"text-icon"},[_c('div',{staticClass:"flex gap-5"},[_c('button',{attrs:{"title":_vm.content.downloadCsvButtonText},on:{"click":function($event){return _vm.downloadCsv(entry.applicationId)}}},[_vm._v(" csv ")]),_c('button',{attrs:{"title":_vm.content.downloadJsonButtonText},on:{"click":function($event){return _vm.downloadJson(entry.applicationId)}}},[_vm._v(" json ")]),_c('button',{attrs:{"title":_vm.content.downloadDataButtonText},on:{"click":function($event){return _vm.downloadJson(
                                            entry.applicationId,
                                            true
                                        )}}},[_vm._v(" data ")]),_c('button',{attrs:{"title":_vm.content.downloadDocumentsButtonText},on:{"click":function($event){return _vm.downloadZip(entry.applicationId)}}},[_vm._v(" zip ")])])]),_c('td',{staticClass:"icon"},[_c('button',{staticClass:"text-secondary text-3xl hover:text-secondary-variant",attrs:{"title":_vm.content.deleteButtonText},on:{"click":function($event){return _vm.prepareDeletingApplication(
                                        entry.applicationId
                                    )}}},[_c('Icon',{attrs:{"id":"Abfall-filled"}})],1)])],2)}),0)])]),_c('AppLayer',{attrs:{"show":_vm.applicationIdToDelete !== null},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('AppContent',{attrs:{"use-large-font":true,"content":_vm.deleteApplicationConfirmationLayerContent.confirmationText}})]},proxy:true},{key:"action1",fn:function(){return [_c('AppButton',{attrs:{"label":_vm.deleteApplicationConfirmationLayerContent
                            .buttonAccept.text},on:{"button-click":function($event){return _vm.deleteApplication()}}})]},proxy:true},{key:"action2",fn:function(){return [_c('AppButton',{attrs:{"label":_vm.deleteApplicationConfirmationLayerContent
                            .buttonCancel.text,"character":"cross"},on:{"button-click":function($event){return _vm.cancelDeletingApplication()}}})]},proxy:true}])}),_c('AppLayer',{attrs:{"show":_vm.migrateApplicantApplicationIds !== null},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('AppContent',{attrs:{"content":_vm.migrateApplicantLayerConfirmationText}}),_c('FormField',{staticClass:"migrate-applicant-target-applicant-id",attrs:{"model":_vm.migrateApplicantTargetApplicantId,"content":{
                        label:
                            _vm.content.migrateApplicantTargetApplicantIdLabel,
                    },"align-field":"belowLabel"}},[_c('FormInputText',{attrs:{"model":_vm.migrateApplicantTargetApplicantId}})],1)]},proxy:true},{key:"action1",fn:function(){return [_c('AppButton',{attrs:{"label":_vm.migrateApplicantLayerContent.buttonAccept.text},on:{"button-click":function($event){return _vm.migrateApplicant()}}})]},proxy:true},{key:"action2",fn:function(){return [_c('AppButton',{attrs:{"label":_vm.migrateApplicantLayerContent.buttonCancel.text,"character":"cross"},on:{"button-click":function($event){return _vm.cancelMigratingApplicant()}}})]},proxy:true}])}),_c('AppLayer',{attrs:{"show":_vm.showExportLayer},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('AppContent',{attrs:{"content":_vm.exportLayerConfirmationText}})]},proxy:true},{key:"action1",fn:function(){return [_c('AppButton',{attrs:{"label":_vm.exportLayerContent.buttonAccept.text},on:{"button-click":function($event){return _vm.exportApplications()}}})]},proxy:true},{key:"action2",fn:function(){return [_c('AppButton',{attrs:{"label":_vm.exportLayerContent.buttonCancel.text,"character":"cross"},on:{"button-click":function($event){return _vm.cancelExportingApplications()}}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }