










































































































































































































































import Vue, { PropType } from 'vue';
import { adminRepository } from '@/api';
import AppAlerts from '@/components/molecules/appAlerts/AppAlerts.vue';
import Icon from '@/components/atoms/icon/Icon.vue';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import AppButton from '@/components/atoms/appButton/AppButton.vue';
import AppContent from '@/components/atoms/appContent/AppContent.vue';
import AppLayer from '@/components/molecules/appLayer/AppLayer.vue';
import AppScrollbar from '@/components/atoms/appScrollbar/AppScrollbar.vue';
import { MessageType } from '@/enums/messageType';
import { alertService } from '@/services/alertService';
import type { Application } from '@/api/interfaces/application/application';
import { SpinnerState } from '@/enums/spinnerState';
import { Address } from '@/api/interfaces/application/address';
import { camelCase, debounce as _debounce, DebouncedFunc } from 'lodash';
import { AdminGridContent } from '@/api/interfaces/content/app/adminGridContent';
import { EntryListContent } from '@/api/interfaces/content/common/entryListContent';
import { ConfirmationLayerContent } from '@/api/interfaces/content/common/confirmationLayerContent';
import { ServiceError } from '@/api/interfaces/serviceError';
import { downloadFile } from '@/services/fileDownloadService';
import { AdminDocumentId } from '@/api/interfaces/admin/adminDocumentId';
import { Pagination } from '@/api/interfaces/common/pagination';
import { AdminUpdateDataModel } from '@/types/app/admin/adminUpdateDataModel';
import AdminPagination from '@/components/organisms/adminPagination/AdminPagination.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import { isServiceError } from '@/api/interfaces/typeGuards';
import { CheckboxField } from '@/types/forms/fields/checkboxField';
import FormField from '@/components/molecules/formField/FormField.vue';
import { TextField } from '@/types/forms/fields/textField';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import { staticStringTemplateLiterals } from '@/utilities/utils';
import { DateRange } from '@/types/forms/shared/dateRange';

interface GridColumn {
    key: string;
}

const gridColumns: GridColumn[] = [
    { key: 'applicantId' },
    { key: 'applicationId' },
    { key: 'status' },
    { key: 'dataVersion' },
    { key: 'applicationNumber' },
    { key: 'created' },
    { key: 'submitted' },
    { key: 'completed' },
];

export default Vue.extend({
    name: 'AdminGrid',
    components: {
        AppAlerts,
        AppButton,
        AppContent,
        AppLayer,
        AppScrollbar,
        Icon,
        SpinnerArea,
        AdminPagination,
        FormField,
        FormBooleanCheckbox,
        FormInputText,
    },
    props: {
        entries: {
            type: Array as PropType<Application[]>,
            default: () => [],
        },
        pagination: {
            type: Object as PropType<Pagination>,
            required: true,
        },
        content: {
            type: Object as PropType<AdminGridContent>,
            required: true,
        },
        deleteApplicationConfirmationLayerContent: {
            type: Object as PropType<ConfirmationLayerContent>,
            required: true,
        },
        migrateApplicantLayerContent: {
            type: Object as PropType<ConfirmationLayerContent>,
            required: true,
        },
        exportLayerContent: {
            type: Object as PropType<ConfirmationLayerContent>,
            required: true,
        },
        enumsContent: {
            type: Object as PropType<EntryListContent>,
            required: true,
        },
        gridSpinner: {
            type: String as PropType<SpinnerState>,
            required: true,
        },
        adminUpdateData: {
            type: Object as PropType<AdminUpdateDataModel>,
            default: new AdminUpdateDataModel(),
        },
    },
    data() {
        return {
            spinner: SpinnerState.off,
            alertService,
            alert: '',
            type: '',
            gridColumns: gridColumns,
            applicationIdToDelete: null as string | null,
            migrateApplicantTargetApplicantId: {
                value: null as string | null,
            } as TextField,
            migrateApplicantApplicationIds: null as string[] | null,
            internalAdminUpdateData: this.adminUpdateData,
            applyMigrationsModel: new CheckboxField({
                value: true,
            }),
            updateDataDebounce: null as null | DebouncedFunc<() => void>,
            showExportLayer: false,
        };
    },
    computed: {
        spinnerState(): SpinnerState {
            return this.gridSpinner == SpinnerState.off
                ? this.spinner
                : this.gridSpinner;
        },
        hasApplications(): boolean {
            return this.entries.length > 0;
        },
        migrateApplicantLayerConfirmationText(): string {
            return staticStringTemplateLiterals(
                this.migrateApplicantLayerContent.confirmationText,
                {
                    migrateApplicantApplicationIds: this
                        .migrateApplicantApplicationIds,
                },
            );
        },
        exportLayerConfirmationText(): string {
            return staticStringTemplateLiterals(
                this.exportLayerContent.confirmationText,
                {
                    paginationTotal: this.pagination?.total,
                },
            );
        },
    },
    methods: {
        camelCase,
        renderApplicationAddress(address: Address): string {
            const streetAndHousenumber = `${address.street ?? ''} ${
                address.houseNumber ?? ''
            }`.trim();
            const meadowAndLot = `${address.meadow ?? ''} ${
                address.lot ?? ''
            }`.trim();
            const subdistrictAndMeadowAndLot = `${
                address.subdistrict ?? ''
            } ${meadowAndLot}`.trim();
            return `${address.postCode} ${address.city}<br />${streetAndHousenumber} ${subdistrictAndMeadowAndLot}`.trim();
        },
        getIconId(lookup: string): string | null {
            switch (lookup) {
                case 'Photovoltaics':
                    return 'Solarenergie-filled';
                case 'PowerPlant':
                    return 'Erzeugung-filled';
                case 'ThermalPower':
                    return 'Windenergie-filled';
                default:
                    return null;
            }
        },
        prepareDeletingApplication(applicationId: string): void {
            this.applicationIdToDelete = applicationId;
        },
        cancelDeletingApplication(): void {
            this.applicationIdToDelete = null;
        },
        deleteApplication(): void {
            if (!this.applicationIdToDelete) {
                return;
            }

            const applicationId = this.applicationIdToDelete;
            this.applicationIdToDelete = null;
            this.spinner = SpinnerState.transparent;

            adminRepository
                .deleteApplication(applicationId)
                .then(() => {
                    alertService.pushAlert({
                        alert: {
                            type: MessageType.info,
                            identifier: 'applications.deleted',
                        },
                    });

                    this.$emit('delete-application', applicationId);
                })
                .catch((error: ServiceError) => {
                    if (isServiceError(error)) {
                        alertService.pushAlert({
                            alert: {
                                type: MessageType.error,
                                identifier: error.messageCode,
                            },
                        });
                    }
                })
                .finally(() => {
                    this.spinner = SpinnerState.off;
                });
        },
        async download(
            documentId: AdminDocumentId,
            applicationId: string,
            jsonDataOnly?: boolean,
        ): Promise<void> {
            this.spinner = SpinnerState.transparent;

            adminRepository
                .downloadDocument(
                    documentId,
                    applicationId,
                    this.applyMigrationsModel?.value || undefined,
                    jsonDataOnly,
                )
                .then((response) => {
                    downloadFile(response.bytes, response.fileName);
                })
                .catch((error: ServiceError) => {
                    if (isServiceError(error)) {
                        alertService.pushAlert({
                            alert: {
                                type: MessageType.error,
                                identifier: error.messageCode,
                            },
                        });
                    }
                })
                .finally(() => {
                    this.spinner = SpinnerState.off;
                });
        },
        async downloadZip(applicationId: string): Promise<void> {
            return this.download('zip', applicationId);
        },
        async downloadCsv(applicationId: string): Promise<void> {
            return this.download('csv', applicationId);
        },
        async downloadJson(
            applicationId: string,
            dataOnly: boolean,
        ): Promise<void> {
            return this.download('json', applicationId, dataOnly);
        },
        handlePageChanged(page: number): void {
            this.internalAdminUpdateData.offset =
                (page - 1) * this.internalAdminUpdateData.limit;
            this.updateData();
        },
        handleQueryChanged(query: string, updateData = true): void {
            this.internalAdminUpdateData.offset = 0;
            this.internalAdminUpdateData.query = query;
            if (updateData) {
                this.updateData();
            }
        },
        handleDateSentAtChanged(
            dateSentAt: DateRange,
            updateData = true,
        ): void {
            this.internalAdminUpdateData.dateSentAtStart = dateSentAt?.start;
            this.internalAdminUpdateData.dateSentAtEnd = dateSentAt?.end;
            if (updateData) {
                this.updateData();
            }
        },
        handleStatusesChanged(statuses: string[], updateData = true): void {
            this.internalAdminUpdateData.statuses = statuses;
            if (updateData) {
                this.updateData();
            }
        },
        handleFilterReset(): void {
            this.handleQueryChanged('', false);
            this.handleDateSentAtChanged({ start: '', end: '' }, false);
            this.handleStatusesChanged([], false);
            this.updateData();
        },
        updateData(): void {
            this.spinner = SpinnerState.transparent;

            if (this.updateDataDebounce) {
                this.updateDataDebounce.cancel();
            }

            this.updateDataDebounce = _debounce(() => {
                this.$emit('update-applications', this.internalAdminUpdateData);
                this.spinner = SpinnerState.off;
            }, 500);

            this.updateDataDebounce();
        },
        prepareMigratingApplicant(): void {
            this.migrateApplicantApplicationIds = this.entries.map(
                (e) => e.applicationId,
            );
        },
        migrateApplicant(): void {
            if (
                !this.migrateApplicantApplicationIds ||
                !this.migrateApplicantApplicationIds.length ||
                !this.migrateApplicantTargetApplicantId.value
            ) {
                alertService.pushAlert({
                    alert: {
                        type: MessageType.error,
                        identifier:
                            'admin.migrateApplicantRequirementsUnfulfilled',
                    },
                });
                return;
            }

            const applicationIds = this.migrateApplicantApplicationIds;
            const targetApplicantId = this.migrateApplicantTargetApplicantId
                .value;
            this.migrateApplicantApplicationIds = null;
            this.migrateApplicantTargetApplicantId.value = null;
            this.spinner = SpinnerState.transparent;

            adminRepository
                .migrateApplicant(applicationIds, targetApplicantId)
                .then(() => {
                    alertService.pushAlert({
                        alert: {
                            type: MessageType.info,
                            identifier: 'admin.migrateApplicantSuccess',
                        },
                    });

                    this.updateData();
                })
                .catch((error: ServiceError) => {
                    if (isServiceError(error)) {
                        alertService.pushAlert({
                            alert: {
                                type: MessageType.error,
                                identifier: error.messageCode,
                            },
                        });
                    }
                })
                .finally(() => {
                    this.spinner = SpinnerState.off;
                });
        },
        cancelMigratingApplicant(): void {
            this.migrateApplicantApplicationIds = null;
            this.migrateApplicantTargetApplicantId.value = null;
        },
        prepareExportingApplications(): void {
            this.showExportLayer = true;
        },
        async exportApplications(): Promise<void> {
            this.spinner = SpinnerState.transparent;

            adminRepository
                .export('csv', this.adminUpdateData)
                .then((response) => {
                    downloadFile(response.bytes, response.fileName);
                })
                .catch((error: ServiceError) => {
                    if (isServiceError(error)) {
                        alertService.pushAlert({
                            alert: {
                                type: MessageType.error,
                                identifier: error.messageCode,
                            },
                        });
                    }
                })
                .finally(() => {
                    this.showExportLayer = false;
                    this.spinner = SpinnerState.off;
                });
        },
        cancelExportingApplications(): void {
            this.showExportLayer = false;
        },
    },
});
