import { render, staticRenderFns } from "./AdminPagination.vue?vue&type=template&id=f8ab8948&scoped=true&"
import script from "./AdminPagination.vue?vue&type=script&lang=ts&"
export * from "./AdminPagination.vue?vue&type=script&lang=ts&"
import style0 from "./AdminPagination.vue?vue&type=style&index=0&id=f8ab8948&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8ab8948",
  null
  
)

export default component.exports