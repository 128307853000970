











































import Vue, { PropType } from 'vue';
import { Pagination } from '@/api/interfaces/common/pagination';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import AppPagination from '@/components/molecules/appPagination/AppPagination.vue';
import { AdminUpdateDataModel } from '@/types/app/admin/adminUpdateDataModel';
import { AdminPaginationContent } from '@/api/interfaces/content/app/adminPaginationContent';
import DatePicker from '@/components/molecules/datePicker/DatePicker.vue';
import FormDropdownCheckboxList from '@/components/molecules/formDropdownCheckboxList/FormDropdownCheckboxList.vue';
import { DateRangeField } from '@/types/forms/fields/dateRangeField';
import { CheckboxesField } from '@/types/forms/fields/checkboxesField';
import {
    EntryListContent,
    EntryListContentFinalLayer,
} from '@/api/interfaces/content/common/entryListContent';
import { FormOption } from '@/types/forms/formOption';
import { CheckboxListContent } from '@/api/interfaces/content/form/base/checkboxListContent';
import { PlaceholderContent } from '@/api/interfaces/content/form/base/placeholderContent';
import AppButton from '@/components/atoms/appButton/AppButton.vue';

export default Vue.extend({
    name: 'AdminPagination',
    components: {
        FormInputText,
        AppPagination,
        DatePicker,
        FormDropdownCheckboxList,
        AppButton,
    },
    props: {
        content: {
            type: Object as PropType<AdminPaginationContent>,
            required: true,
        },
        pagination: {
            type: Object as PropType<Pagination>,
            required: true,
        },
        adminUpdateData: {
            type: Object as PropType<AdminUpdateDataModel>,
            default: new AdminUpdateDataModel(),
        },
        enumsContent: {
            type: Object as PropType<EntryListContent>,
            required: true,
        },
    },
    data() {
        return {
            queryModel: {
                value: this.adminUpdateData.query,
            },
            filterDateSentAtModel: new DateRangeField({
                value: {
                    start: this.adminUpdateData.dateSentAtStart,
                    end: this.adminUpdateData.dateSentAtEnd,
                },
            }),
            filterStatusModel: new CheckboxesField({
                value: this.adminUpdateData.statuses,
                options: [],
            }),
            filterStatusContent: {
                options: {},
                ...this.content.filterStatus,
            } as CheckboxListContent & PlaceholderContent,
        };
    },
    computed: {
        page(): number {
            const page =
                1 + Math.floor(this.pagination.offset / this.pagination.limit);
            return isNaN(page) ? 1 : page;
        },
        texts(): { first: string; last: string; count: string } {
            return {
                first: this.content.paginationTextFirst,
                last: this.content.paginationTextLast,
                count: this.content.paginationTextCount,
            };
        },
    },
    watch: {
        'queryModel.value'() {
            this.$emit('query-changed', this.queryModel.value);
        },
        'filterDateSentAtModel.value': {
            handler(): void {
                this.$emit(
                    'date-sent-at-changed',
                    this.filterDateSentAtModel.value,
                );
            },
            deep: true,
        },
        'filterStatusModel.value': {
            handler(): void {
                this.$emit('statuses-changed', this.filterStatusModel.value);
            },
            deep: true,
        },
    },
    created(): void {
        for (const key of Object.keys(this.enumsContent.applicationStatus)) {
            this.filterStatusModel.options.push(new FormOption({ key: key }));
            this.filterStatusContent.options[key] = {
                label: (this.enumsContent
                    .applicationStatus as EntryListContentFinalLayer)[key],
            };
        }
    },
    methods: {
        handlePageChanged(page: number): void {
            this.$emit('page-changed', page);
        },
        handleFilterResetClicked(): void {
            this.queryModel.value = '';
            this.filterDateSentAtModel.value = { start: '', end: '' };
            this.filterStatusModel.value = [];
            this.$emit('filter-reset');
        },
    },
});
